.content-edit-form {
	.form-group {
		position: relative;

		&.third-length {
			display: inline-block;
			width: 30%;
			margin-right: 1%;
		}

		&.half-length {
			display: inline-block;
			width: 48%;
			margin-right: 2%;

			~ .half-length {
				width: 48%;
				margin-right: 0;
			}
		}

		label {
			display: inline-block;
			margin-top: 15px;
			margin-bottom: 3px;
			font: $font-2;
			font-size: 1.2em;
		}

		.form-control {
			display: block;
			width: 100%;
			min-height: 45px;
			padding: .5rem .75rem;
			font: $font-2;
			font-size: 1.2em;
			line-height: 1.25;
			color: #5f6b6b;
			background-color: #fff;
			background-image: none;
			background-clip: padding-box;
			border: 1px solid rgba(0,0,0,0.15);
			border-radius: 0.25rem;
			transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
		}

		&.form-image {
			.form-control {
				padding: .5rem 105px .5rem .75rem;
			}
		}
		&.form-mainimage {
			.form-control {
				padding: .5rem 105px .5rem .75rem;
			}
		}

		.btn-cf-open-filebrowser {
			height: 45px;
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 0 15px;
			background-color: #00a9a6;
			border: 0;
			border-bottom-right-radius: 0.25rem;
			border-top-right-radius: 0.25rem;
			font: 300 1.2em Oswald,sans-serif;
			letter-spacing: 1.2px;
			color: #fff;
			cursor: pointer;
		}

		&.form-submit {
			margin-top: 20px;
			margin-left: -0.58em;
			margin-right: -0.58em;
			margin-bottom: -0.58em;
			padding: 0.58em 0;
			background: #00a9a6;
			border-top: 1px solid #333;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			text-align: center;

			.cms-btn {
				width: 80%;
				text-transform: uppercase;
			}
		}

		.cms-btn {
			padding: 11px 15px;
			border: 0;
			border-radius: 0.25rem;
			font: 300 1.2em Oswald,sans-serif;
			color: #fff;
			text-decoration: none;
			text-transform: initial;
			letter-spacing: 1.2px;
			cursor: pointer;
		}

		&.form-delete {
			margin-top: 20px;
			text-align: right;
		}

		.cms-btn-delete {
			background: #d62828;
		}
	}
	.logo-group {
		display: none;
		&.show-field {
			display:block;
		}
	}
	.header-group {
		display: none;
		&.show-field {
			display: block;
		}
	}
}

.content-edit-form {
	.repeater {
		h3 {
			margin-top: 15px;
			color: #333;
		}

		.repeater-item {
			margin-bottom: 10px;
			padding: 10px 20px 20px;
			border: 1px solid #333;
			border-radius: 0.25rem;

			&:last-child {
				margin-bottom: 20px;
			}

			.form-delete {
				.cms-btn-delete:disabled {
					opacity: .65;
					cursor: not-allowed;
				}
			}
		}
	}

	table.k-editor {
		border-collapse: initial;
		border-spacing: 0;
		border: 1px solid rgba(0,0,0,0.15);

		.k-editor-toolbar-wrap {
			border-bottom: 1px solid rgba(0,0,0,0.15);

			.k-editor-toolbar {
				padding-left: 5px;

				.k-tool {
					width: 40px;
					color: #555;
				}
			}
		}

		.k-content {
			border: 0;
			border-radius: 4px;
		}
	}
}

.k-widget.k-window {
	.k-viewhtml-dialog {
		.k-editor-textarea {
			margin: 0 1%;
			width: 98%;
		}
	}
}