
.large-popup-ad {
    background-color: #cba557;
    position: relative;
    width: 90%;
    max-height: 600px;
    border: 4px solid #2d1f1a;
    -webkit-box-shadow: 0 0 30px -10px #c7c7c7;
    -moz-box-shadow: 0 0 30px -10px #c7c7c7;
    box-shadow: 0 0 30px -10px #c7c7c7;
    padding: 20px;

    &:before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: 110%;
        top: 0px;
        left: 0;
        width: 100%;
        height: 50px;
        z-index: 0;
    }

    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #2D1F1A;
    }

    .content {
        color: $white;
        padding: 40px 0 10px;
        width: 100%;
        text-align: center;

        p {
            font-family: $font-1;
            font-size: 1rem;
            text-transform: uppercase;
            color: $white;
            line-height: normal;
            text-shadow: 0 1px 0 rgba(0,0,0,0.5);
            letter-spacing: 2px;
            font-weight: 600;
        }

        .savings {
            font-size: 4rem;
            font-family: $font-1;
            margin: 0;
            font-weight: normal;
        }

        .info {
            margin-top: 10px;
            font-family: $font-1;
            text-transform: none;
            font-weight: normal;
        }

        .disclaimer {
            font-size: 12px;
            font-family: $font-1;
            text-transform: none;
            font-weight: normal;
        }

        .button {
            margin-top: 10px;
        }
    }

    @media #{$mq-largeScreen} {
        width: 600px;
        max-height: none;

        &:before {
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: 101%;
            top: 0px;
            left: 0;
            width: 100%;
            height: 150px;
            z-index: 0;
        }

        .close-button {
            top: 20px;
        }

        .content {
            padding:0;

            p {
                font-size: 1.6rem;
            }

            .savings {
                font-size: 8rem;
                font-family: $font-1;
            }

            .info {
                margin-top: 0;
                font-weight: normal;
                font-size: 1.2rem;
                letter-spacing: initial;
            }
        }
    }
}

.popup-container {
    position: fixed;
    bottom: 75px;
    right: 30px;
    z-index: 99;

    .close-icon {
        width: 100%;
        height: 30px;
        text-align: right;
        padding-right: 10px;
        z-index: 8;
        top: 35px;
        position: relative;

        .fa-remove:before, .fa-close:before, .fa-times:before {
            font-size: 25px;
        }
    }

    .safehouse-popup {
        position: relative;
        display: block;
        width: 215px;
        height: auto;
        background-color: #cba557;
        border: 4px solid #2d1f1a;
        box-shadow: -3px 4px 5px 0px rgba(199,199,199,0.23);
        max-height: 200px;
        overflow: hidden;
        

        &:before {
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: 102%;
            top: -5px;
            left: -8px;
            width: 106%;
            height: 27px;
            z-index: 0;
        }

        .content {
            display: block;
            position: relative;
            padding: 30px 0 0;
            z-index: 7;
            text-align: center;





            p {
                color: #2d1f1a;
                text-transform: inherit;
                margin: 0;
                line-height: 24px;
                font-size: 24px;
                font-family: "News Cycle", serif;
            }



            .savings {
                font-size: 1.8rem;
                margin: 0;
            }

            .info {
                font-weight: normal;
                font-size: 0.9rem;
            }
        }
    }

    @media #{$mq-largeScreen} {
        bottom: 8%;
        right: 1%;
    }
}

.large-popup-ad .content h1, .large-popup-ad .content h4, .large-popup-ad .content h5, .large-popup-ad .content h6 {
    font: 26px/30px "Kameron", sans-serif;
    font-weight: bold;
    color: #2d1f1a;
    text-transform: capitalize;
    margin: 0;
    padding: 10px 0 0;
}

.large-popup-ad .content h2 {
    font: 55px/55px "Kameron", sans-serif;
    font-weight: bold;
    color: #2d1f1a;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
}

.large-popup-ad .content h3 {
    font: 40px/40px "Kameron", sans-serif;
    font-weight: bold;
    color: #2d1f1a;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
}

.large-popup-ad .content p, .large-popup-ad .content span {
    color: #2d1f1a;
    text-transform: inherit;
    margin: 0;
    line-height: 32px;
    font-size: 22px;
    font-family: "News Cycle", serif;
    font-weight: bold;
    padding: 30px 0;
}
.large-popup-ad .content p a, .large-popup-ad .content span a {
    color: #2d1f1a;
}

.large-popup-ad .content .moredetail {
    color: #fff;
    text-transform: inherit;
    margin: 0;
    line-height: 18px;
    font-size: 18px;
    font-family: "News Cycle", serif;
    background: #737a35;
    border: 2px solid #2d1f1a;
    display: inline-block;
    padding: 15px 50px;
    text-transform: capitalize;
    /*position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translate(-50%, 0);*/ letter-spacing: 1px;
}

    .large-popup-ad .content .moredetail a {
        color: #fff;
        text-decoration:none;display:block;
    }
.popup-container .safehouse-popup .content h2 {
    font: 24px/24px "News Cycle", serif;
    font-weight: bold;
    color: #2d1f1a;
    text-transform: inherit;
    margin: 0;
    padding: 0 0 10px;
}
.popup-container .safehouse-popup .content h3 {
    font: 30px/30px "Kameron", sans-serif;
    font-weight: bold;
    color: #2d1f1a;
    text-transform: capitalize;
    margin: 0;
    padding: 0 0 15px;
}
.popup-container .safehouse-popup .content .clickto-lm {
    color: #cba557;
    text-transform: inherit;
    margin: 0;
    line-height: 18px;
    font-size: 14px;
    font-family: "News Cycle", serif;
    background: #2d1f1a;
    border: 0;
    display: inline-block;
    padding: 15px 0;
    text-transform: capitalize;
    width: 100%;
    letter-spacing: 1px;
    font-weight: bold;
}

.popup-container .safehouse-popup .content .clickto-lm a {
    color: #cba557;
    text-decoration: none;
    display: block;
}
.popup-container .safehouse-popup .content h2 a, .popup-container .safehouse-popup .content h3 a, a.my_popup_open {
    text-decoration: none !important;
}
@media(max-width:767px) {
    .large-popup-ad .content h1 {
        font-size: 21px;
    }

    .large-popup-ad .content h3 {
        font-size: 30px;
    }

    .large-popup-ad .content p {
        line-height: 26px;
        font-size: 20px;
        padding: 20px 0 50px;
    }

    .large-popup-ad .content h2 {
        line-height: 40px;
        font-size: 40px;
    }

    .large-popup-ad .content .moredetail {
        padding: 15px;
    }
    .popup-container {
        bottom: 20px;
        right: 20px;
    }
}