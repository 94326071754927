@mixin clearfix($pseudo: after) {
	&:#{$pseudo} {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		font-display: swap;
		src: $src;
	}
}

@mixin font-smoothing($value: antialiased) {
	@if $value == antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

@mixin section-block {
	// recurring widget style that I can't think of a good name for
	@at-root .section-dark & {
		padding: 2em 0 2em;

		@media #{$mq-mediumScreen} {
			padding: 3em 0 2.75em;
		}
	}

	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.slider-dots {
			margin: 0;
		}
	}

	&-title {
		margin: 0;
	}

	&-container {
		@extend %container;
	}
}

/***
 * Usage:
 * @include flex-span(3, 1em)
 * Creates flex items 1/3 of the full with with 1em gutters
 */
@mixin flex-columns($width, $gutter, $fallback: $width - 2.5%) {
	@if type_of($width) == number and unit($width) == '' // not a unit
	{
		$width: $width * 100%; // convert to percent
	}

	flex: 0 0 $fallback;
	flex: 0 0 calc(#{$width} - #{$gutter});
	margin-right: $gutter;
}

/**
 * Examples:
 * @include box-shadow(0, 1); // box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
 * @include box-shadow(-1, 1, true); // box-shadow: inset -3px 3px 3px rgba(0, 0, 0, 0.2);
 **/
@mixin box-shadow($x: 0, $y: 0, $inset: false) {
	@if (abs($x) > 1 or round($x) != $x) {
		@error '$x must be -1, 0, or 1';
	}

	@if (abs($y) > 1 or round($y) != $y) {
		@error '$y must be -1, 0, or 1';
	}

	$x-pos: $bxs-offset * $x;
	$y-pos: $bxs-offset * $y;
	$blur: $bxs-blur;
	$color: rgba($bxs-color, $bxs-opacity);

	/*@if ($x == 0 and $y == 0) {
		$blur: $bxs-blur *2;
		$color: rgba($bxs-color, $bxs-opacity * 2);
	}*/

	@if ($inset) {
		box-shadow: inset $x-pos $y-pos $blur $color;
	} @else {
		box-shadow: $x-pos $y-pos $blur $color;
	}
}
