.button {
	font-weight: normal;
	font-family: $font-1;
	letter-spacing: 2px;
	line-height: 1;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	padding: 10px 20px;
	display: inline-block;
	min-width: 150px;
	text-align: center;
	border: 2px solid;
	margin: 0;
	margin-right: 0.25rem;
	transition: 0.3s;

	&:hover, &:focus {
		text-decoration: none;
	}

	&-1 {
		background: $color-1;
		border-color: $color-1;
		color: #ffffff;

		&:hover, &:focus {
			background-color: transparent;
			color: $color-1;
		}
	}

	&-2 {
		background: $color-2;
		border-color: $color-2;
		color: $white;

		&:hover, &:focus {
			border-color: $color-2;
			background: $white;
			color: $color-2;
		}
	}
	&-3 {
		background: transparent;
		border-color: $white;
		color: $white;
		&:hover, &:focus {
			border-color: $white;
			background: $white;
			color: $color-3;
		}
	}

	&:disabled {
		background: #666;
		border-color: #666;
		color: #ffffff;
		cursor: not-allowed;

		&:hover, &:focus {
			background: #666;
		}

	}
}