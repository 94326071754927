.splash-page {
    position: fixed;
    z-index: 100;
    background-color: $color-1;
    width: 100%;
    height: 100%;
    color: $white;
    display: none;

    svg {
        pointer-events: none;
    }

    &-header {
        text-align: center;

        img {
            max-width: 250px;
            margin: 0 auto;
            padding: 25px 0px;
        }

        h3 {
            font-family: $font-1;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 1.25em;
        }
    }

    &-boot {
        display: none;
        order: 2;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
    }

    &-content {
        &-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            margin: 20px 0px;
        }

        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        margin: 0px 20px;

        &-header {
            display: flex;
            align-items: center;
        }

        h2, h4 {
            text-transform: uppercase;
        }

        h2 {
            font-size: 1.875rem;
            margin: 10px 0px;
            font-weight: bold;
            max-width: 220px;
        }

        svg {
            color: $color-3
        }

        .fa-map-marker-alt {
            font-size: 50px;
        }

        &:hover {
            .bubbles {
                display: block;
            }
        }
    }

    .lincoln-content {
        order: 3;

        &:hover ~ .splash-page-boot {
            transform: rotate(30deg);
        }
    }

    .milwaukee-content {
        order: 1;

        &:hover ~ .splash-page-boot {
            transform: rotate(-30deg);
        }

        &:after {
            content: "";
            background-color: $color-3;
            width: 100%;
            height: 5px;
            margin: 30px auto;
        }
    }

    .bubbles {
        width: 100%;
        height: auto;
        display: none;
        position: absolute;

        circle {
            stroke: white;
            fill: none;
        }

        > g > g:nth-of-type(3n) circle {
            stroke: #996E2C;
        }

        > g > g:nth-of-type(4n) circle {
            stroke: #BD8737;
        }
    }

    .bubbles-large {
        overflow: visible;

        > g {
            transform: translateY(2048px);
            opacity: 0;
            will-change: transform, opacity;
        }

        g:nth-of-type(1) {
            animation: up 5.5s infinite;

            g {
                transform: translateX(350px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }

        g:nth-of-type(2) {
            animation: up 4.25s 250ms infinite;

            g {
                transform: translateX(450px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }

        g:nth-of-type(3) {
            animation: up 5s 550ms infinite;

            g {
                transform: translateX(700px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }

        g:nth-of-type(4) {
            animation: up 4.5s 1.5s infinite;

            g {
                transform: translateX(500px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }

        g:nth-of-type(5) {
            animation: up 4.5s 3s infinite;

            g {
                transform: translateX(675px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }
    }

    .bubbles-small {
        overflow: visible;

        > g {
            transform: translateY(2048px);
            opacity: 0;
            will-change: transform, opacity;
        }

        g circle {
            transform: scale(0);
        }

        g:nth-of-type(1) {
            animation: up 3.25s infinite;

            g {
                transform: translateX(350px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }

        g:nth-of-type(2) {
            animation: up 3.75s infinite;

            g {
                transform: translateX(750px);
            }

            circle {
                animation: wobble 3s infinite ease-in-out;
            }
        }

        g:nth-of-type(3) {
            animation: up 3.25s 150ms infinite;

            g {
                transform: translateX(350px);
            }

            circle {
                animation: wobble 3s 150ms infinite ease-in-out;
            }
        }

        g:nth-of-type(4) {
            animation: up 5.75s 225ms infinite;

            g {
                transform: translateX(180px);
            }

            circle {
                animation: wobble 3s 225ms infinite ease-in-out;
            }
        }

        g:nth-of-type(5) {
            animation: up 6s 75ms infinite;

            g {
                transform: translateX(350px);
            }

            circle {
                animation: wobble 3s 75ms infinite ease-in-out;
            }
        }

        g:nth-of-type(6) {
            animation: up 5.13s 150ms infinite;

            g {
                transform: translateX(650px);
            }

            circle {
                animation: wobble 3s 75ms infinite ease-in-out;
            }
        }

        g:nth-of-type(7) {
            animation: up 6.25s 175ms infinite;

            g {
                transform: translateX(480px);
            }

            circle {
                animation: wobble 3s 225ms infinite ease-in-out;
            }
        }

        g:nth-of-type(8) {
            animation: up 7s 100ms infinite;

            g {
                transform: translateX(330px);
            }

            circle {
                animation: wobble 3s 195ms infinite ease-in-out;
            }
        }

        g:nth-of-type(9) {
            animation: up 6.25s 133ms infinite;

            g {
                transform: translateX(230px);
            }

            circle {
                animation: wobble 3s 275ms infinite ease-in-out;
            }
        }

        g:nth-of-type(10) {
            animation: up 6s 600ms infinite;

            g {
                transform: translateX(730px);
            }

            circle {
                animation: wobble 2s 905ms infinite ease-in-out;
            }
        }
    }

    @keyframes wobble {

        33% {
            transform: translateX(-50px);
        }

        66% {
            transform: translateX(50px);
        }
    }

    @keyframes up {

        0% {
            opacity: 0;
        }

        10%, 90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: translateY(-1024px);
        }
    }

    @media #{$mq-largeScreen} {
        &-header {
            img {
                padding: 25px 0px;
            }

            h3 {
                font-size: 2.25em;
            }
        }

        &-content {
            &-container {
                margin: 45px 0px;
                flex-direction: row;
            }

            &-header {
                flex-direction: column;
            }

            h2 {
                font-size: 3.875rem;
                margin: 25px 0px;
                max-width: 300px;
            }

            align-items: center;
            margin: 0px 20px 130px;

            .fa-map-marker-alt {
                font-size: 60px;
            }
        }

        &-boot {
            display: block;
        }

        .milwaukee-content {
            &:after {
                display: none;
            }
        }
    }
}
