.testimonial {
    &-container {
        p {
            color: $color-2;
            font-size: 2.25rem;
            text-align: center;
            margin: 0 auto;
            position: relative;
            &:after {
                content: "";
                display: block;
                background-color: $color-2;
                width: 50px;
                height: 3px;
                margin: 0 auto;
                position: absolute;
                bottom: -20px;
                left: 0;
                right:0;
            }
            @media #{$mq-largeScreen} {
                max-width: 75%;
            }
        }
    }
}