// region Media Queries
$mq-mobileOnly: "only screen and (max-width:1024px)";
$mq-smallScreen: "only screen and (min-width:480px)";
$mq-mediumScreen: "only screen and (min-width:768px)";
$mq-largeScreen: "only screen and (min-width:1025px)";
$mq-xLargeScreen: "only screen and (min-width:1200px)";
$mq-xxLargeScreen: "only screen and (min-width:1400px)";
// To Use... @media #{$mq-mediumScreen}{ }
// endregion

// region Colors
$color-1: #2D1F1A;
$color-2: #737A35;
$color-3: #996E2C;
$color-4: #883815;
// endregion
$white: #FFF;
// region Fonts
$font-1: 'News Cycle', serif;
$font-2: 'Kameron', sans-serif;
// endregion

// region Other
$assets-root: '/miller-time-pub/dist'; 
$margin-mobile: 1.125rem;
$margin-desktop: 3.125rem;
// endregion


// region Box Shadow Config
// Used to configure box-shadow mixin
$bxs-offset: 3px;
$bxs-blur: 3px;
$bxs-color: #000;
$bxs-opacity: 0.2;
// endregion