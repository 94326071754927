.section {
	@extend %section;
}
.right-content {
    @media #{$mq-largeScreen} {
        width:100%;
        float: left;    
    }    
}
.right-contentin {
    @media #{$mq-largeScreen} {
        width: calc( 100% - 275px);
        float: right;
    }
}

.pencil-bar {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: .5em 0;

    a {
        font-weight: normal;
        text-decoration: underline;
        display: inline-block;
        letter-spacing: 2px;
        vertical-align: middle;
        color: #c8123f;
    }
}

.floating-icon {
    position: relative;    
    z-index: 1;
    img {
        max-width: 250px;
        margin: 0 auto;
    }    
    @media #{$mq-largeScreen} {
        position: absolute;
        right: 165px;  
        img {
            margin: auto;  
        }
        
    }
}
.email-signup-top {
    display: none;
    @media #{$mq-largeScreen} {
        position: fixed;
        animation: emailDown 2s ease-in;
        z-index: 100;
        top: 0;
        right: 30px;
        display: flex;
        padding: 10px 20px 10px 10px;
        background-color: $color-4;
        color: $white;
        letter-spacing: 1px;
        align-items: center;
        text-decoration: none;
        svg {
            margin: 0px 10px;
        }
    }
    @keyframes emailDown {
        from {
            transform: translateY(-100px);
        }
        to {
            transform: translateY(0px);
        }
    }
}

@media(max-width:1199px) {
.email-unsub{
    padding:20px;
}
.email-unsub h1{font-size:30px;}
.email-unsub p {
    font-size: 20px;
}
}
@media(max-width:567px) {
.button-1 {
    margin-top: 20px !important;
}
.email-unsub h1 {
    font-size: 22px;
}

.email-unsub p {
    font-size: 18px;
}
}