.form {
	&-wrapper {
		h2, h3, h4 {
			text-align: center;
			margin-bottom: 0;
		}

		h3, h4 {
			color: $color-3;
		}

		.sub-header {
			font-family: $font-1;
			font-weight: normal;
			margin-bottom: 1em;
			font-size: 1.125rem;
			text-transform: uppercase;
			letter-spacing: 3px;
		}
	}

	&-columns {
		margin-top: 50px;

		&:last-child {
			border-top: 1px solid $color-3;
			margin-top: 50px;
			padding-top: 50px;

			@media #{$mq-largeScreen} {
				margin-top: 100px;
				padding-top: 100px;
			}
		}

		fieldset {
			legend {
				margin-bottom: 20px;
				font-family: $font-1;
				color: $color-3;
				font-size: 1.25rem;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
		}
	}

	&-fields {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 30px;

		@media only screen and (min-width:875px) {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
		}

		&-full {
			grid-template-columns: 1fr;
		}

		&-right {
			text-align: right;

			.g-recaptcha {
				div {
					display: inline;
					text-align: right;
				}
			}

			button {
				margin-right: 0;
			}
		}
	}

	&-field {
		position: relative;
		margin: 20px 0;

		&-text {
			input, textarea {
				position: relative;
				z-index: 2;
				display: block;
				padding: 15px 10px 12px;
				background-color: transparent;
				border: none;
				font-family: $font-1;
				font-size: 1rem;
				max-width: none;
				width: 100%;
				letter-spacing: 0.5px;
				border-bottom: 2px dashed $color-2;

				&::placeholder {
					opacity: 0;
				}
			}

			textarea {
				min-height: 60px;
				height: 150px;
				max-height: 360px;
				resize: vertical;
				border: 2px dashed $color-2;
			}
		}

		&-select {
			select {
				position: relative;
				z-index: 2;
				display: block;
				padding: 15px 10px 12px;
				background-color: transparent;
				border: none;
				font-family: $font-1;
				font-size: 1rem;
				max-width: none;
				width: 100%;
				letter-spacing: 0.5px;
				border-radius: 0;
				border-bottom: 2px dashed $color-2;

				&::placeholder {
					opacity: 0;
				}
			}

			span {
				display: none;
			}

			@supports (-webkit-appearance: none) {
				select {
					-webkit-appearance: none;
				}

				span {
					display: flex;
					position: absolute;
					justify-content: center;
					align-items: center;
					right: 0;
					top: 0;
					pointer-events: none;
					width: 53px;
					height: 100%;
					background: none;
					color: $color-1;
					z-index: 2;

					svg {
						font-size: 1.5em;
					}
				}
			}
		}

		&-radio {
			.form-options {
				.form-radio {
					display: inline-block;
					color: $color-2;
					margin-right: 1.5rem;
					vertical-align: middle;

					&-label {
						&:before {
							content: '○';
							font-size: 1.5em;
							margin-right: 0.75rem;
							vertical-align: middle;
						}
					}

					input {
						position: absolute;
						left: -9999px;

						&:checked ~ .form-radio-label:before {
							content: '●';
						}
					}
				}
			}
		}

		&-opt-in {
			.form-checkbox {
				&-label {
					display: flex;
					align-items: center;

					h1, h2, h3, h4, h5, h6, p {
						margin: 0;
					}
				}

				&-ui {
					border: 2px solid $color-2;
					color: $color-2;
					font-family: $font-1;
					height: 2ex;
					width: 2ex;
					margin-right: 0.5em;
					box-sizing: border-box;
					flex: 0 0 auto;
					font-size: 2rem;

					.fa-check {
						display: none;
						font-size: 0.75em;
					}
				}

				input {
					position: absolute;
					left: -9999px;

					&:checked ~ .form-checkbox-label .form-checkbox-ui .fa-check {
						display: block;
					}
				}
			}
		}

		&-label {
			display: block;
			padding: 5px;
			background: $white;
			color: $color-1;
			font-family: $font-1;
			font-size: 1em;
			font-style: italic;
			line-height: 1;
			font-weight: bold;
		}

		&-placeholder {
			position: absolute;
			top: 15px;
			left: 0px;
			text-transform: none;
			// transform: translateY(-50%);
			// transform-origin: top left;
			font-size: 1.125rem;
			border-radius: 5px;
			transition: 0.5s;
			z-index: 3;
			pointer-events: none;
		}


		&-date-select {
			span {
				display: flex;
				position: absolute;
				justify-content: center;
				align-items: center;
				right: 0;
				top: 0;
				pointer-events: none;
				width: 53px;
				height: 100%;
				background: none;
				color: $color-1;
				z-index: 2;

				svg {
					font-size: 1.3em;
				}
			}
		}

		&.focused, &.not-empty, &.prefilled {
			.form-field-placeholder {
				top: -15px;
				left: 0px;
				transform: none;
				font-size: 1rem;
			}
		}
	}

	&-success {
		display: none;
		text-align: center;
	}

	&-table {
		display: block;
		margin: $margin-mobile 0;

		&-headers {
			display: none;
		}

		&-body {
			display: block;
		}

		&-row {
			display: block;
			border-bottom: 1px solid #bbb;

			td {
				display: block;
				margin: $margin-mobile 0;
			}
		}
	}

	@media #{$mq-largeScreen} {
		.columns {
			padding: 0;

			.column {
				margin: 0 1rem;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&-field {
			&-date-select {
				display: block;

				.form-field-select {
					select {
						min-width: 120px;
					}

					&:not(:last-child) {
						select {
							border-right-style: solid;
						}
					}
				}
			}
		}

		&-table {
			display: table;
			margin: 1rem 0;

			&-headers {
				display: table-header-group;

				td {
					padding: 0 12px;

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						padding-right: 0;
					}
				}
			}

			&-body {
				display: table-row-group;

				td {
					padding: 6px 12px;

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						padding-right: 0;
					}
				}
			}

			&-row {
				display: table-row;
				border: none;

				td {
					display: table-cell;
					vertical-align: middle;
					margin: 0;

					.form-field {
						margin: 0;
					}
				}
			}
		}
	}

	&.footer-actions-form {
		width: 100%;

		.form-field {
			position: relative;
			margin: 0;

			label {
				top: 50%;
				background: $color-3;
				padding: 5px;
				color: $white;
				font-family: $font-1;
				font-size: 1.25rem;
				font-style: italic;
			}

			#footer-email {
				padding-right: 53px;
				color: $white;
				font-size: 1.25rem;
			}

			.email-button {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				font-size: 0;
				padding: 0 20px;
				background: $color-2;
				color: $white;
				border: none;
				z-index: 2;

				svg {
					font-size: 1.5rem;
				}
			}

			&.focused {
				label {
					font-size: 1rem;
					top: 0;
				}
			}
		}
	}
}

/***** Datepickers ******/
.ui-datepicker {
	border-color: $color-2 !important;
	z-index: 35 !important;

    &.ui-widget-content {
        color: $color-1;
    }

    .ui-datepicker-header {
        background-color: transparent;
        color: $color-1;
        border: none;

        .ui-datepicker-year {
            display: none;
        }

        .ui-state-hover {
            background-color: transparent;
            color: $color-1;
            border: none;
        }

        a {
            &:hover {
                cursor: pointer;
            }

            .ui-state-disabled {
                cursor: default;
            }
        }

        .ui-icon {
            width: auto;
            height: auto;
            top: 25%;
            background-image: none;
            font-size: 1.5em;
            text-indent: 0;
            color: $color-1;
        }
    }

    td {
        span, a {
            text-align: center;
        }
    }

    .ui-state-default {
        border: none;
        background-color: transparent;
        font-weight: 700;
        color: $color-1;
    }

    .ui-state-active {
        border: none;
        background: $color-1;
        font-weight: normal;
        color: #ffffff;
    }

    .ui-state-hover {
        border: none;
        background: $color-1;
        font-weight: normal;
        color: #ffffff;
    }

    .dp-hightlight {
        .ui-state-default {
            border: none;
            background: $color-1;
            font-weight: normal;
            color: #ffffff;
        }
    }
}

.recaptcha-border {
	border: 2px solid red;
	border-radius: 4px;
	display: inline-block;
}

.error-message {
	display: none;
	text-align: right;
	margin-right: 5%;

	p {
		color: red;
		font-size: 1rem;
	}
}