.color {
    h3 {
        color: #fff !important;
    }
}

.image-copy {
    display: flex;
    flex-direction: column;
    margin: $margin-mobile 0px;

    &-right {
        display: flex;
        flex-direction: column;
    }

    &-image {
        flex-basis: 50%;
        position: relative;

        img {
            height: 800px;
            min-width: inherit;
            min-height: inherit;
            object-fit: cover;
            width: 800px;
        }
    }

    &-content {
        flex-basis: 50%;
        display: flex;
        padding: 20px 20px;
        position: relative;

        &-icon {
            max-width: 75px;
        }

        h2, h3, h4 {
            margin: 10px 0px;
        }

        h3, h4 {
            color: $color-3;
        }

        a {
            color: $white;
        }

        .button {
            background: $color-2;
            border-color: $color-2;
            color: $white;

            &:hover, &:focus {
                border-color: $white;
                background: $white;
                color: $color-2;
            }
        }

        .sub-header {
            font-family: $font-1;
            font-weight: normal;
            margin-bottom: 1em;
            font-size: 1.125rem;
            text-transform: uppercase;
            letter-spacing: 3px;
        }
    }


    @media #{$mq-largeScreen} {
        &-right {
            flex-direction: row-reverse;
            margin: 0;
            padding: 30px 30px 0 30px;

            .image-copy-content {
                padding: 0 30px 10px 0px;
            }

            &.offset {
                .image-copy-image {
                    align-self: flex-start;
                    padding-right: 20px;
                }

                .image-copy-content {
                    align-self: flex-start;
                    padding: 0px 30px 30px 30px
                }
            }
        }

        &-left {
            flex-direction: row;
            margin: $margin-desktop 0px $margin-desktop 0px;

            &.offset {
                .image-copy-image {
                    align-self: flex-start;
                    padding-left: 20px;
                }

                .image-copy-content {
                    align-self: flex-start;
                }
            }

            .image-copy-content {
                padding: 0 30px;
            }
        }

        &.offset {
            flex-direction: row;

            .image-copy-image {
                width: 50%;
                position: inherit;
                padding-bottom: 20px;
                top: inherit;

                img {
                    object-fit: cover;
                }
            }

            .image-copy-content {
                margin-top: 0;
                width: 50%;
            }
        }
    }

    @media #{$mq-xxLargeScreen} {
        &.offset {
            .image-copy-image {
                width: 50%;
            }

            .image-copy-content {
                width: 50%;
            }
        }
    }
}

.color {
    background-color: $color-3;

    &-image-copy-pattern {
        display: block;
        position: absolute;
        max-width: 55%;
        right: 0;
        top: 0;
    }

    .image-copy-content {
        color: $white;
        background-image: url("/miller-time-pub/dev/images/bricks-white.svg");
        background-repeat: repeat-y;
        background-position: right;

        h2, h4 {
            color: $white;
        }
    }

    .button {
        background: transparent;
        border-color: $white;
        color: $white;

        &:hover, &:focus {
            background-color: $white;
            color: $color-2;
        }
    }
}

.white {
    .color-image-copy-pattern {
        display: none;
    }
}

.align-bottom {
    align-self: flex-end;
}

.align-top {
    align-self: flex-start;
}
@media only screen and (max-width: 1919px) {
    .image-copy-image img {
        height: 680px;
        width: 680px;
    }   
}
@media only screen and (max-width: 1599px) {
    .image-copy-image img {
        height: 560px;
        width: 560px;
    }
}
@media only screen and (max-width: 1439px) {
    .image-copy-image img {
        height: 525px;
        width: 525px;
    }
}
@media only screen and (max-width: 1359px) {
    .image-copy-image img {
        height: 490px;
        width: 490px;
    }
}
@media only screen and (max-width: 1199px) {
    .image-copy-image img {
        height: 400px;
        width: 100%;
        max-width: 100%;
    }
}
@media only screen and (max-width: 767px) {
    .image-copy-image img {
        height: auto;
        width: auto;
        max-width: 100%;
    }
}