.ctablock {
    padding: 2.5rem 0px;
    background-image: url(/miller-time-pub/dev/images/bricks.svg);
    background-size: auto 50%;
    background-position: right;
    background-repeat: repeat-y;

    &-content {
        background-color: $color-3;
        padding: 50px;
        text-align: center;
        color: $white;

        h2 {
            font-size: 4em;
            font-weight: 700;
            margin-bottom: 15px;
        }

        h3, h4 {
            margin: 0 auto;
            padding-bottom: 20px;
        }

        .sub-header {
            font-family: $font-1;
            font-weight: normal;
            margin-bottom: 1em;
            font-size: 1.125rem;
            text-transform: uppercase;
            letter-spacing: 3px;
        }
    }

    @media #{$mq-largeScreen} {
        padding: $margin-desktop 30px;

        &-content {
            h3, h4 {
                max-width: 50%;
            }
        }
    }
}
