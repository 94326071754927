@import "abstract/variables",
        "abstract/mixins",
        "abstract/placeholder";

@import "vendors/reset",
        "slick-carousel/slick/slick.scss",
        "slick-carousel/slick/slick-theme.scss";

@import "base/fonts",
        "base/defaults",
        "base/layout";

@import "components/buttons",
        "components/form",
        "components/blocksEditor",
        "components/menus",
        "components/popup";

@import "modules/navigation",
        "modules/footer",
        "modules/splash";

@import "blocks/image-copy/widget",
        "blocks/intro-copy/widget",
        "blocks/hero/widget",
        "blocks/testimonial/widget",
        "blocks/cta/widget",
        "blocks/fullwidth-image/widget";