.header {
	position: relative;
	display: flex;
	flex-direction: column;
	background: $color-4;
    width: 100%;            
	z-index: 30;
    top: 0;    
    &__container {
        display: flex;
        justify-content: space-evenly;
        &:after {
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-color: $color-4;
            z-index: 5;            
        }
    }
    @media #{$mq-largeScreen}{
        position: fixed;
        width: 275px;
        height: 100%;
        padding: 40px 0px;
        float: left;
        &__container {
            &:after {
                display: none;
            }
        }        
    }    
	&__branding {
		position: relative;
		margin: auto 0;
		padding: 10px 1rem;
		flex: 0 1 auto;
		z-index: 30;
		background: $color-4;
        align-self: center;
        order: 2;        
		a {
			img {
				max-width: 150px;
			}			
		}
	}
	&__nav {
		//display: none;
		position: absolute;
		width: 100%;		
		left: 0;
        z-index: 3;
		top: 100%;
		text-align: center;
	}
	&__reserve {
		flex: 1 1 auto;
		min-height: 100%;
		z-index: 30;
		background: $color-4;
		&__button {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: bold;
			padding: 10px;
			color: $white;
			background-color: $color-1;
			text-align: center;
			transition: background-color 300ms;
			&:hover, &:focus {
				background-color: darken($color-1, 5%);
			}
			.mobile-hide {
				display: none;
			}
		}
	}
	&__control, &__hours {
		position: relative;
		flex: 0 0 15%;
		align-self: stretch;
		z-index: 30;
        background: $color-4;    
        padding: 0px 10px;    
    }   
    &__hours {
        order: 3;
    } 
    &__bottom { 
		display: none;      
		a {
			text-decoration: none;
		}              
    }
    &__location__information {
        display: none;
	}
	&__book {
		z-index: 10;
		background-color: $color-2;
		width: 100%;
		text-align: center;
		a {
			padding: 20px 0px;
			display: block;
			text-decoration: none;
			color: $white;
			text-transform: uppercase;

		}
	}
	@at-root .edit-mode & {
		position: relative;
	}
	@media #{$mq-smallScreen} {
		&__branding {
			max-width: 300px;
		}

    }
    @media #{$mq-largeScreen} {
        &__container {
            display: block;
        }
        &__nav {
			top: 0;
        }
        &__branding {
            a {
                img {
                    max-width: 200px;
                }
            }
        }
        &__bottom {
            display: block;
            text-align: center;
            padding: 50px 0px;
            &__email {
                display: block;
                color: $white;
				margin-top: 20px;				
                svg {
                    margin-right: 5px;
                }
            } 
		}
		&__book {
			display: none;
		}
        &__location__information {
            display: block;
            text-align: center;
            padding: 15px 0px 25px;
            &__address {
				text-transform: uppercase;				
            }
            a {
				color: $white;                
				text-decoration: none;
            }
        }
    }    
	@media #{$mq-xLargeScreen} {        
		&__branding {
			padding: 0 40px;
			flex: 0 0 20%;
        }        
		&__nav {
			position: relative;
			display: block;
			flex: 1 1 auto;
			z-index: 20;
		}
		&__reserve {
			position: relative;
			flex: 0 0 auto;
			&__button {
				padding: 30px;
				.mobile-hide {
					display: inline;
				}
			}
		}
		&__control {
			display: none;
        }        
	}
}
.hours {
    &__toggle{
        display: flex;
		flex-direction: column;
		justify-content: center;
		text-decoration: none;
		position: relative;
		width: 100%;
		height: 100%;
        margin: auto 0;
        align-items: center;
        color: $white;
        svg {
            font-size: 20px;
        }
        @media #{$mq-largeScreen} {
            display: none;
        }
	}
	&-container {
		transform: translateY(-200%);
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 3;
		top: 100%;
		background-color: $color-1;
		p {
			color: $white;
		}
		@media #{$mq-largeScreen} {
            display: none;
        }
	}

}
.mobile__bottom{
	display: none;
	padding: 20px 0px;
	&__location {
		padding: 20px 0px;
	}
	&__phone {
		padding: 20px 0px;
	}
	a {
		color: #FFF;
		text-decoration: none;
	}
	@media #{$mq-largeScreen} {
		display: none;
	}
}
.nav {
	&__wrapper {
		pointer-events: none;
	}
	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		transition: 500ms;
		z-index: 20;
		&--primary {
			transform: translateY(-100%);
		}
		&--secondary {
			overflow: hidden;
		}
	}
	&__item {
		position: relative;
		list-style-type: none;
		display: block;
		&.child-open {
			.nav__link--primary {
				background-color: $color-1;
			}
			.nav__link__child-toggle {
				background-color: darken($color-1, 5%);
				&:before {

				content: '-';
				}
			}
		}
		&:not(.child-open) {
			.nav__list--secondary {
				max-height: 0 !important;
			}
		}
	}
	&__link {
		position: relative;
		display: block;
		width: 100%;
		padding: 1rem 2rem;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 400;
		color: $white;
		transition: background-color 300ms;
		letter-spacing: 2px;
		&__child-toggle {
			position: absolute;
			top: 50%;
			right: 0;
			padding: 0 15px;
			transform: translateY(-50%);
			height: 100%;
			transition: background-color 300ms;
			background: $color-1;
			&:before {
				content: '+';
				font-size: 2rem;
				font-weight: bold;
			}
		}
		&--primary {
			background-color: $color-1;
			@media #{$mq-largeScreen} {
				background-color: $color-4;
			}
		}
		&--secondary {
			background-color: darken($color-1, 5%);
		}
	}
	&__toggle {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-decoration: none;
		position: relative;
		width: 100%;
        height: 100%;
        align-items: center;
        margin: auto 0;
        color: $white;

		$bar-width: 32px;
		$bar-height: 4px;
		$bar-spacing: 10px;
        
        svg {
            font-size: 20px;
        }

		.bar,
		.bar:after,
		.bar:before {
			width: $bar-width;
			height: $bar-height;
		}

		.bar {
			position: relative;
			//transform: translateY($bar-spacing);
			transition: all 0ms 300ms;
			background: #fff;
			margin: auto;
		}

		.bar:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: $bar-spacing;
			background: rgba(255, 255, 255, 1);
			transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}

		.bar:after {
			content: "";
			position: absolute;
			left: 0;
			top: $bar-spacing;
			background: rgba(255, 255, 255, 1);
			transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}
	}
	@media #{$mq-xLargeScreen} {
		&__wrapper {
			pointer-events: auto;
		}
		&__list {
			transform: none;
			position: relative;
			&--primary {
				display: flex;
                justify-content: space-around;
                flex-direction: column;
			}
			&--secondary {
				display: none;
				position: absolute;
				left: 0;
				min-width: 100%;
			}
		}
		&__item {
			flex: 1 0 auto;
			&.child-open {
				.nav__link--primary {
					background-color: $color-4;
					&:hover, &:focus {
						background-color: rgba($white, 0.3);
					}
				}
				.nav__list--secondary {
					display: block;
					max-height: none !important;
				}
			}
		}
		&__link {
			text-align: center;
			outline: none;
			&__child-toggle {
				display: none;
			}
			&--primary {
				padding: 20px;
				&:hover, &:focus {
					background-color: rgba($white, 0.3);
				}
			}
			&--secondary {
				text-align: left;
				background-color: $color-1;
				&:hover, &:focus {
					background-color: darken($color-1, 5%);
				}
			}
		}
	}
}
.mobile-overlay {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	background-color:$color-1;
	z-index: 5;
	overflow: hidden;
}
.mobile-overlay.nav-open {
	display: inline-block;
}
.header.nav-open {
	&:after {
		content: "";
	}
	.nav__wrapper {
		pointer-events: auto;
	}
	.nav__list--primary {
		transform: translateY(0);
	}
	.nav__toggle {
		.bar {
			background: rgba(255, 255, 255, 0);

			&:after {
				top: 0;
				transform: rotate(45deg);
				transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
			}

			&:before {
				bottom: 0;
				transform: rotate(-45deg);
				transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
			}
		}
	}
}

.header.hours-open{
	.hours-container {
		transform: translateY(0);
		transition: 500ms;
		letter-spacing: 1.8px;
		padding: 50px 0px;
		text-align: center;
		h5 {
			text-transform: uppercase;			
			color: $white;
		}
	}
}

.skip-nav {
	display: none;

	@media #{$mq-largeScreen} {
		display: block;
		text-transform: uppercase;
		font-weight: 700;
		background-color: $color-1;
		color: $white;
		outline: none;
		letter-spacing: 2px;
		font-size: 0.9em;
		padding: 20px 13px 5px;
		position: absolute;
		top: -15px;
		left: 25%;
		transform: translateY(-100%);
		transition: opacity 200ms 1s, transform 200ms 1s;
		opacity: 0;
		z-index: /* Over */
			9000;

		&:link, &:visited, &:hover {
			color: $white;
		}

		&:focus {
			text-decoration: underline;
			opacity: 1;
			transform: translateY(0);
			transition-delay: 0s;
		}
	}
}
@media screen and (max-width: 1919px) {
	.nav__link--primary {
		padding: 15px 20px;
	}
}
@media screen and (max-width: 1439px) {
	.nav__link--primary {
		padding: 10px 20px;
	}
}
.header__bottom {
	padding: 30px 0px 0;
}

@media all and (max-width: 1439px) and (max-height: 768px) {
	.nav__link--primary {
		padding: 5px 20px;
		font-size: 16px;
	}
}

@media all and (max-width: 1359px) and (max-height: 900px) {
	.nav__link--primary {
		padding: 10px 20px;
		font-size: 20px;
	}
}

@media all and (max-width: 1359px) and (max-height: 768px) {
	.nav__link--primary {
		padding: 5px 20px;
		font-size: 18px;
	}
}