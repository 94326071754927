.hero {
    &-container {
        margin-bottom: $margin-mobile;

        img {
            height: 67vh;
            object-fit: cover;
        }

        @media #{$mq-largeScreen} {
            &:after {
                content: " ";
                background-image: url("/miller-time-pub/dev/images/bricks.svg");
                display: block;
                position: absolute;
                right: 0;
                bottom: 20%;
                height: 55%;
                width: 275px;
                background-repeat: repeat-y;
                z-index: 0;
            }

            margin-bottom: 10%;
        }
    }

    &-content {
        background-color: $color-3;
        padding: 25px 20px;
        color: $white;
        text-align: center;

        img {
            max-width: 70px;
            height: auto;
            display: inline;
        }

        h1 {
            margin-bottom: 0;
            line-height: 0.9;
        }

        .sub-header {
            font-family: $font-1;
            font-weight: normal;
            margin-bottom: 1em;
            font-size: 1.125rem;
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        @media #{$mq-largeScreen} {
            max-width: 30%;
            padding: 30px 35px;
            position: absolute;
            bottom: 25%;
            right: 20px;
            z-index: 1;
        }
    }
}
