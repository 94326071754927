.menus {
	&-wrap {
		overflow: visible;
		position: relative;
	}

	&-nav {
		text-align: center;
		position: sticky;
		top: 150px;
		margin: 0px 0px 25px;
		padding: 10px;

		.anchors {
			&-list {
				display: none;
			}

			&-mobile {
				position: relative;

				@supports (-webkit-appearance: none) {
					.anchors-select {
						-webkit-appearance: none;
					}

					span {
						display: flex;
						position: absolute;
						justify-content: center;
						align-items: center;
						right: 0;
						top: 0;
						pointer-events: none;
						width: 53px;
						height: 100%;
						background: $color-2;
						color: $white;
						z-index: 2;

						svg {
							font-size: 1.5em;
						}
					}
				}

				.anchors-select {
					display: block;
					padding: 15px 10px 12px;
					border: 2px solid $color-2;
					border-radius: 0;
					color: $color-2;
					font-size: 18px;
					font-family: $font-1;
					font-style: italic;
					font-weight: 400;
					width: 100%;


				}
			}

		}
	}


	@media #{$mq-largeScreen} {

		&-nav {
			position: relative;
			top: 0;
			background-color: transparent;

			.anchors {
				&-list {
					display: block;

					.button {
						margin: 2.5px;
					}
				}

				&-mobile {
					display: none;
				}
			}
		}

		&-list {
			.division {
				margin-top: 75px;
				padding-top: 75px;
				border-top: 3px solid $color-3;

				&:first-child {
					margin-top: 0;
					padding-bottom: 0;
					border-top: none;
				}
			}
		}
	}
}

.menu {
	&-header {
		text-align: center;
	}

	&-section {
		border-bottom: 3px solid $color-3;
		margin-bottom: 25px;
		padding-bottom: 25px;
		&-description {
			text-align: center;
			p {
				font-family: $font-2;
			}			
		}

		@media #{$mq-largeScreen} {
			margin-bottom: 75px;
			padding-bottom: 75px;
		}

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}

		&-header {
			text-align: center;
			font-size: 32px;
			color: $color-2;
		}

		
	}

	&-item {
		margin: 10px 0;
		padding: 20px 10px;
		page-break-inside: avoid;

		&.feature-item {
			border: 1px solid $color-2;
		}

		&:first-child {
			margin-top: 0;
		}

		&-header {
			h4 {
				font-family: $font-2;
				text-transform: uppercase;
				color: $color-3;
				font-style: normal;
				font-size: 1.2em;
				margin-bottom: 10px;
				letter-spacing: 1px;

				img {
					width: 30px;
					display: inline;
					padding-left: 5px;
				}
			}
		}

		&-description {
			padding-top: 0.2rem;
			font-family: $font-1;

			&:empty {
				border-top-style: dotted;
			}
		}
	}

	&-addons {
		&-header {
			color: $color-1;
		}
	}

	&-footnote {
		p {
			font-size: 0.875em;
			font-style: italic;
		}
	}

	@media #{$mq-mediumScreen} {
		&-items {
			columns: 2;
			column-gap: 25px;
			column-fill: balance;

			&.no-columns {
				columns: 1;
			}
		}
	}

	@media #{$mq-largeScreen} {
		&-items {
			columns: 3;
			column-gap: 25px;
			column-fill: balance;

			&.no-columns {
				columns: 1;
			}
		}
	}
}
