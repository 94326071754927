.footer {
    padding: 25px 25px 25px 300px;
    text-align: center;
    display: inline-block;
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 50;

    &-email-signup-container {
        padding: 50px 0px 150px;
        width: 100%;
        position: relative;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;

        .footer-email-signup {
            padding: 0px 25px;

            h3 {
                margin-bottom: 10px;
                line-height: 2.1875rem;
                margin-top: 20px;
            }

            h4 {
                color: $color-3;
            }

            #msg {
                display: none;
                border: 2px solid $color-2;
                background-color: #FAFDDD;
                text-align: center;
                padding: 15px 0px;

                span {
                    color: $color-2;
                }
            }

            .footer-email-form {
                position: relative;

                &-submit {
                    position: absolute;
                    right: 0;
                    top: 10px;
                    background: transparent;
                    border: none;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-family: $font-1;
                    color: $color-1;
                    z-index: 5;
                }
            }
        }

        img {
            max-width: 75px;
        }

        &:after {
            content: "";
            background-image: url("/miller-time-pub/dev/images/bricks.svg");
            display: block;
            position: absolute;
            right: 0;
            bottom: -53px;
            height: 235px;
            width: 275px;
            background-repeat: no-repeat;
        }
    }

    &-location {
        display: flex;
        flex-direction: column;

        &-instagram {
            display: flex;
            margin-bottom: -4px;

            &-one, &-two {
                max-width: 50%;
            }

            svg {
                position: absolute;
                top: 15px;
                left: 15px;
                color: $white;
                font-size: 1.875rem;
            }

            img {
                max-height: 185px;
                height: 200px;
                object-fit: cover;
            }
        }

        &-details {
            a, p {
                color: $white;
                margin-bottom: 0px;
                text-decoration: none;
                font-size: 1.125em;
                font-family: $font-2;
            }

            &-address {
                padding-bottom: 15px;
            }
        }

        &-information {
            /*flex-basis: 50%;*/
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            background-color: $color-3;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 30px 0px;
            position: relative;
            font-family: $font-2;

            &-backToTop {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: $white;
                padding: 10px;
                background-color: $color-4;
                top: -30px;
                text-decoration: none;

                &-arrow {
                    border-width: 0 8px 10px 8px;
                    border-color: transparent transparent $white;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    padding: 0;
                    border-radius: 3px;
                }
            }

            img {
                max-width: 215px;
                padding-bottom: 30px;
            }
        }
    }

    p {
        margin-bottom: 0;
        padding: 10px 0px
    }

    &-links {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        a {
            text-decoration: none;
            font-size: 1rem;
            display: inline-block;
            padding: 0px 4px;

            &:hover {
                text-decoration: underline;
            }
        }

        &-social {
            padding: 15px 0px;

            a {
                font-size: 1.25rem;
                padding: 0px 10px;
            }
        }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        p {
            font-size: 1rem;
        }

        .footer-legal {
            a {
                padding: 0px 10px;
            }
        }
    }

    @media #{$mq-largeScreen} {
        &-links {
            flex-direction: row;
        }

        &-bottom {
            flex-direction: row;
        }

        &-location {
            flex-direction: row;

            &-information {
                &-backToTop {
                    right: 30px;
                }
            }

            &-instagram {
                flex-basis: 50%;
                max-width: 50%;

                img {
                    max-height: 300px;
                    height: 300px;
                }
            }
        }

        &-email-signup-container {
            padding: 50px 40px;
            flex-direction: row;
            align-items: flex-start;

            .footer-email-signup {
                max-width: 75%;

                h4 {
                    max-width: 80%;
                }

                .footer-email-form {
                    max-width: 90%;
                }

                p {
                    max-width: 67%;
                }
            }

            &:after {
                bottom: 0;
            }
        }
    }
}
@media all and (max-width: 1439px) and (max-height: 768px) {
    .header {
        padding: 20px 0px;
    }
    .header__location__information {
        padding: 15px 0px;
    }
    .header__bottom {
        padding: 20px 0px 0;
    }
    .footer {
        padding: 15px 15px 15px 300px;
    }
}
@media all and (max-width: 1359px) and (max-height: 900px) {
    .header {
        padding: 40px 0px;
    }
    .header__location__information {
        padding: 15px 0px 25px;
    }
    .header__bottom {
        padding: 30px 0px 0;
    }
    .footer {
        padding: 25px 25px 25px 300px;
    }
}

@media all and (max-width: 1359px) and (max-height: 768px) {
    .header {
        padding: 30px 0px;
    }
    .header__location__information {
        padding: 15px 0px;
    }
    .header__bottom {
        padding: 20px 0px 0;
    }
    .footer {
        padding: 15px 15px 15px 300px;
    }
}
@media all and (max-width: 1199px){ 
    .footer {
        padding: 15px;
    }
}