body {
	font-family: $font-1;
	font-size: 1.125rem;
	color: $color-1;
}

h1 {
	font-size: 4em;
	font-family: $font-2;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.0625em;
	margin-bottom: 0.5em;
}

h2,h3 {
	font-family: $font-2;
	font-size: 2.25em;
	font-weight: normal;	
	letter-spacing: 0px;
	margin-bottom: 0.75em;
	line-height: 1;
}

h4, h5, h6 {
	font-family: $font-1;
	font-weight: normal;
	margin-bottom: 1em;
	font-size: 1.125rem;
	text-transform: uppercase;
	letter-spacing: 3px;
}

p {
	font-family: $font-1;
	letter-spacing: 0px;
	line-height: 1.5;
	margin-bottom: 1em;
	font-size: 1.25rem;
}

a{
	color: $color-1;
	text-decoration: underline dotted $color-2;	
}
img {
	display: block;
	width: 100%;
}
a, strong, em, b, i, span, label, button, input {
	font-size: 1em; // These should always inherit; dunno why reset.scss sets them to the root size.
}
li {
	font-size: 1.25rem;
}