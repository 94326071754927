.intro-copy {
    h2 {
        font-size: 2.25em;
        font-family: $font-2;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.0625em;
        margin-bottom: 0.5em;
    }

    h3, h4 {
        color: $color-4;
    }

    .sub-header {
        font-family: $font-1;
        font-weight: normal;
        margin-bottom: 1em;
        font-size: 1.125rem;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    ul {
        margin: 0;
        padding: 0px 20px 20px;
    }

    li {
        list-style: none;
        padding: 5px 0px;

        &:before {
            content: "·";
            padding: 0px 5px 0px 0px;
            font-size: 50px;
            vertical-align: middle;
            line-height: 20px;
        }
    }

    @media #{$mq-mediumScreen} {
        max-width: 60%;
        margin: 0 auto;
    }
}
